import axios from 'axios'
import $ from 'jquery'
const authenticity_token=window.authenticity_token
//const dev=window.dev
const current_client=window.current_client

export const templates_app={
    mounted:function(){
        this.get_templates()
    },
    data() {
        return {
            prueba:"hola",
            templates:[],
            new_template:{name:null,category:"UTILITY",language:"es",header:null,body:null,footer:null},
            examples:{header:[],body:[]},
            spinners:{get_templates:false,create_template:false}
        }
    },
    methods: {
        detectPlaceholder:function(section){
            const placeholderRegex = /\{\{(\d+)\}\}/g;
            let match;
            const existingPlaceholders = new Set();
            // Detectar todos los placeholders en el texto actual
            while ((match = placeholderRegex.exec(this.new_template[section])) !== null) {
              const placeholderNumber = parseInt(match[1], 10);
              existingPlaceholders.add(placeholderNumber);
            }// Asegurarse de que el número de ejemplos coincida con el número de placeholders
            this.examples[section] = Array.from(existingPlaceholders).map(n => this.examples[n - 1] || "");
        },
        openmodal:function(modal_id){
            $("#"+modal_id).modal("show")
        },
        closemodal:function(modal_id){
            $("#"+modal_id).modal("hide")
        },
        get_templates:function(){
            this.spinners.get_templates=true
            axios.get(`/clients/${current_client}/templates.json`).then(res=>{
                this.spinners.get_templates=false
                this.templates=res.data.data
                }).catch(err=>{
                    alert("error get_templates",err)
                })
        },
        create_template:function(){
            this.spinners.create_template=true
            axios.post(`/clients/${current_client}/templates`,{authenticity_token:authenticity_token,template:{base:this.new_template,examples:this.examples}}).then(res=>{
                this.spinners.create_template=false
                this.templates.push(res.data)
                this.new_template={name:null,category:"UTILITY",language:"es",header:null,body:null,footer:null}
                this.examples={header:[],body:[]}
            }).catch(err=>{
                this.spinners.create_template=false
                console.log("error create_template",err);
                alert("error create_template",err.response.data)    
            })
        },
        delete_template:function(template_id,name){
            axios.delete(`/clients/${current_client}/templates/${template_id}&${name}`).then(res=>{
                console.log("delete_template",res)
                this.get_templates()
            }).catch(err=>{
                alert("error delete_template",err)
            })
        },
        status_class:function(status){
            switch(status){
                case "APPROVED":
                    return "bg-success"
                    break;
                case "REJECTED":
                    return "bg-danger"
                    break;
                default:
                    return "bg-warning"
            }
        }
    },
    computed:{
        disable_form:function(){
            let basic = (
                !this.new_template.name ||
                !this.new_template.category ||
                !this.new_template.language ||
                !this.new_template.body 
            )
            let header_examples = this.examples.header.some(e=>!e)
            let body_examples = this.examples.body.some(e=>!e)
            return basic || header_examples || body_examples
            //return basic
        }
    }
}